$mobile-break-point: 520px;

#sameRacerSection {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ff5d15;
	z-index: 3;
}

#sameRacerSection::before {
	content: "";
	background-image: url(https://assets.sentrack.com.au/images/background-texture.png);
	background-size: contain;
	background-repeat: repeat;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	mix-blend-mode: screen;
}

.poweredBy {
	font-size: calc(var(--default-font-size) * 0.75);
	line-height: calc(var(--default-font-size) * 0.75);
	margin-top: var(--paragraph-margin-top);
}

.sr {
	width: 100%;
}

.bb {
	width: 5vw;
	margin-left: 0.5vw;
	vertical-align: initial;
}

.sameRacerMockup {
	filter: drop-shadow(-10px 10px 10px rgba(0, 0, 0, 0.5));
}

@media only screen and (max-width: $mobile-break-point) {
	.poweredBy {
		margin-top: 10px;
		font-size: calc(var(--default-font-size));
	}

	.bb {
		height: calc(var(--default-font-size));
		margin-left: 0.5em;
		width: auto;
	}

	.mobileText {
		font-size: calc(var(--default-font-size) * 1.9);
		line-height: calc(var(--default-font-size) * 1.9);
	}

	.sr {
		width: 90%;
	}
}
