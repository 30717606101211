$mobile-break-point: 520px;

.whiteText {
	color: white;
	max-width: 80%;
	margin: auto;
	margin-top: var(--paragraph-margin-top);
}

.senGroupLogo {
	width: 75%;
	margin-top: 0px;
	margin-bottom: var(--paragraph-margin-top);
}

#landingSection {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 5;
	background-color: #0f2131;
}

#landingSection::before {
	content: "";
	background-image: url(https://assets.sentrack.com.au/images/background-texture.png);
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.1;
}

@media only screen and (max-width: $mobile-break-point) {
	.senGroupLogo {
		margin-bottom: 0px;
	}

	.whiteText {
		font-size: calc(var(--default-font-size) * 1.9);
		line-height: calc(var(--default-font-size) * 1.9);
		margin-top: 30px;
	}
}
