$mobile-break-point: 520px;

#trackSection {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 4;
	background-color: white;
}

#trackSection::before {
	content: "";
	background-image: url(https://assets.sentrack.com.au/images/background-texture-white.png);
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.1;
}

.greenText {
	color: #048855;
	max-width: 80%;
	margin: auto;
	margin-top: var(--paragraph-margin-top);
}

.trackLogo {
	width: 40%;
	margin-top: 0px;
	margin-bottom: var(--paragraph-margin-top);
}

@media only screen and (max-width: $mobile-break-point) {
	.trackLogo {
		width: 40%;
		margin-bottom: 0px;
	}

	.greenText {
		font-size: calc(var(--default-font-size) * 1.9);
		line-height: calc(var(--default-font-size) * 1.9);
	}
}
