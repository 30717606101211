$mobile-break-point: 520px;

#survivorSection {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(to left, rgb(254, 81, 46), rgb(240, 150, 25));
	z-index: 1;
}

#survivorSection::before {
	content: "";
	background-image: url(https://assets.sentrack.com.au/images/background-texture.png);
	background-size: contain;
	background-repeat: repeat;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	mix-blend-mode: screen;
}

.survivorLogo {
	width: 100%;
	margin: auto;
	height: auto;
}

.s {
	width: 60%;
}

@media only screen and (max-width: $mobile-break-point) {
	.mobileText {
		font-size: calc(var(--default-font-size) * 1.7);
		line-height: calc(var(--default-font-size) * 1.7);
	}
}
