$mobile-break-point: 520px;

#quaddieSection {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #3f84e5;
	z-index: 2;
}

#quaddieSection::before {
	content: "";
	background-image: url(https://assets.sentrack.com.au/images/background-texture.png);
	background-size: contain;
	background-repeat: repeat;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	mix-blend-mode: screen;
}

.q {
	max-height: 200px;
}

@media only screen and (max-width: $mobile-break-point) {
	.q {
		width: 60%;
	}

	.mobileText {
		font-size: calc(var(--default-font-size) * 1.9);
		line-height: calc(var(--default-font-size) * 1.9);
	}
}
