:root {
	--vh: 1vh;
	--tab-height: 100px;
	--container-width: 80vw;
	--autoGrid-min-size: 39vw;
	--default-font-size: 1.5vw;
	--paragraph-margin-top: 20px;
}

$mobile-break-point: 520px;

// Fonts
@font-face {
	font-family: "Proxima Nova Black";
	src: url("https://assets.sentrack.com.au/fonts/Proxima+Nova+A+Black.otf") format("opentype");
}

// Styles
* {
	font-family: "Proxima Nova Black"; //, -apple-system, BlinkMacSystemFont, sans-serif;
	color: white;
	text-transform: uppercase;
}

html {
	background: #fafafa;
}

body {
	margin: 0;
}

section {
	height: calc(95 * var(--vh));
	box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.2);
}

p {
	margin-bottom: 0;
	margin-top: var(--paragraph-margin-top);
}

a {
	cursor: pointer;
	text-decoration: none;
}

/* General */

.catchPhrase {
	font-size: calc(var(--default-font-size));
	line-height: calc(var(--default-font-size));
	max-width: 90vw;
	margin: auto;
	margin-top: var(--paragraph-margin-top);
}

.displayTable {
	margin: auto;
	display: table;
	width: 100%;
	height: 100%;
}

.displayInline {
	display: inline;
}

.container {
	padding: var(--header-margin-top-bottom) var(--header-margin-left-right);
	max-width: var(--container-width);
	margin: auto;
	display: table;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.containerInner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.autoGrid {
	padding: 0;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(var(--autoGrid-min-size), 1fr));
	grid-gap: 1rem;
	list-style-type: none;
}

.autoGrid li {
	text-align: center;
	font-size: 1.2rem;
	color: #ffffff;
	max-height: calc(70 * var(--vh));
}

.projectGridImage {
	max-width: 200px;
}

.mockup {
	max-width: 100%;
	max-height: 100%;
}

.label {
	margin-top: var(--paragraph-margin-top);
}

@media only screen and (max-width: $mobile-break-point) {
	.container {
		margin: 0px;
		max-width: 100%;
	}

	.autoGrid {
		grid-template-columns: auto;
		height: calc(95 * var(--vh));
		grid-gap: 0;
	}

	.autoGrid li {
		height: 100%;
		max-height: calc(45 * var(--vh));
	}

	.catchPhrase {
		font-size: 1em;
		line-height: 1em;
		margin-top: var(--paragraph-margin-top);
	}

	.mockup {
		max-height: calc(50 * var(--vh));
		margin-top: var(--paragraph-margin-top);
	}
}
